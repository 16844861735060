<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <b>
                  <p class="mb-0 ml-2" style="font-size: 18px">
                    SOLICITUD TIF
                    <span
                      class="badge"
                      :class="
                        form.estado == 1
                          ? 'badge-info'
                          : form.estado == 2
                          ? 'badge-success'
                          : ''
                      "
                    >
                      {{ form.nEstado }}
                    </span>
                  </p>
                </b>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">TIF</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card shadow">
              <legend class="well-legend text-bold bg-frontera text-light">
                Formulario {{ accion }} Solicitud
                <span v-if="form.id"> # {{ form.id }}</span>
              </legend>
              <div class="row">
                <div class="col-sm-9">
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label for="fecha">Fecha requerimiento solicitud</label>
                      <input
                        type="date"
                        :min="hoy"
                        class="form-control form-control-sm shadow-sm"
                        id="fecha"
                        v-model="form.fecha_solicitud"
                        v-on:input="$v.form.fecha_solicitud.$touch"
                        :class="
                          $v.form.fecha_solicitud.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="form.estado == 2 || bloquear"
                        @change="validaFecha()"
                      />
                      <div
                        v-if="
                          (!$v.form.fecha_solicitud.$dirty ||
                            $v.form.fecha_solicitud.$invalid) &&
                            form.fecha_solicitud == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="observaciones">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm shadow-sm"
                        v-model="form.observaciones"
                        v-on:input="$v.form.observaciones.$touch"
                        :class="
                          $v.form.observaciones.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="form.estado == 2 || bloquear"
                      >
                      </textarea>
                      <div
                        v-if="
                          (!$v.form.observaciones.$dirty ||
                            $v.form.observaciones.$invalid) &&
                            form.observaciones == ''
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="justificacion">Justificación</label>
                      <textarea
                        class="form-control form-control-sm  shadow-sm"
                        id="justificacion"
                        v-model="form.justificacion"
                        :disabled="form.estado == 2 || bloquear"
                      >
                      </textarea>
                    </div>
                    <div class="form-group col-md-3" v-if="this.form.id">
                      <label for="justificacion">Estado</label>
                      <select
                        class="form-control form-control-sm 
                         shadow-sm"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        v-model="form.estado"
                        :disabled="bloquear"
                      >
                        <option value="">seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-3">
                      <button
                        class="btn bg-primary  shadow"
                        v-show="!$v.form.$invalid"
                        @click="save(1)"
                        v-if="
                          (!bloquear &&
                            $store.getters.can(
                              'funcionariofrontera.tifSolicitudes.edit'
                            )) ||
                            (!bloquear &&
                              $store.getters.can(
                                'funcionariofrontera.tifSolicitudes.create'
                              ))
                        "
                      >
                        <i class="fas fa-paper-plane"></i>
                        <div>
                          Guardar
                        </div>
                      </button>
                      <button
                        class="btn bg-secondary ml-1  shadow"
                        @click="validacionVolver()"
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div
                    class="card card-outline card-navy mr-0 col-md-12 p-2 shadow"
                  >
                    <div class="card-header pt-2 pb-2">
                      <h3 class="card-title">
                        <h6>
                          <i class="fas fa-users"></i>
                          Datos de Usuario
                        </h6>
                      </h3>
                    </div>
                    <div>
                      <div class="form-group">
                        <label for="user">Usuario</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="user"
                          v-model="form.user.name"
                          :class="
                            $v.form.user.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="correo">Correo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="correo"
                          v-model="form.user.email"
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="celular">Celular</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="celular"
                          v-model="form.user.cel"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <!-- DETALLE -->
            <div v-show="form.id != null">
              <fieldset class="well card shadow">
                <legend class="well-legend text-bold bg-frontera text-light">
                  <div class="form-group m-1">
                    Detalles
                    <button
                      class="btn btn-sm bg-gradient-success float-right shadow-sm"
                      data-toggle="modal"
                      data-target="#modal-form-detalle-solicitud"
                      @click="llenarModal('Crear', null)"
                      v-if="
                        !bloquear &&
                          $store.getters.can(
                            'funcionariofrontera.tifSolicitudes.createDetalle'
                          )
                      "
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </legend>
                <DetSolicitudForm ref="DetSolicitudForm" />
                <div v-for="(detalle, index) in form.detalles" :key="index">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-navy card-outline collapsed-show">
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <i class="fas fa-clipboard-list"></i> Detalle
                          <span class="badge bg-gradient-navy">
                            {{ index + 1 }}
                          </span>
                        </h3>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-navy"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-solicitud"
                            @click="llenarModal('Editar', detalle)"
                            v-if="
                              !bloquear &&
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.editDetalle'
                                )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            class="btn btn-sm bg-gradient-danger"
                            @click="destroy(detalle.id)"
                            v-if="
                              !bloquear &&
                                $store.getters.can(
                                  'funcionariofrontera.tifSolicitudes.deleteDetalle'
                                )
                            "
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                          <div class="card-tools">
                            <button
                              type="button"
                              data-card-widget="collapse"
                              data-toggle="tooltip"
                              title="Collapse"
                              class="btn btn-tool"
                            >
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <!-- <div class="card card-info card-outline collapsed-show">
                          <div class="card-header pt-2 pb-2">
                            <h3 class="card-title">Bloques/Operaciones</h3>

                            <div class="btn-group float-right">
                              <div class="card-tools">
                                <button
                                  type="button"
                                  data-card-widget="collapse"
                                  data-toggle="tooltip"
                                  title="Collapse"
                                  class="btn btn-tool"
                                >
                                  <i class="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <div class="row justify-content-center">
                              <div
                                class="col-md-4"
                                v-for="(bloque, index) in detalle.bloques"
                                :key="index"
                              >
                                <div class="card shadow">
                                  <div class="card-header p-1">
                                    <h5 class="card-title">
                                      <i
                                        class="nav-icon fas fa-th text-primary"
                                      ></i>
                                      {{ bloque.nombre }}
                                    </h5>
                                  </div>
                                  <div class="card-body p-2">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label for="">Operaciones</label>
                                          <div
                                            v-for="(operacion,
                                            opIndex) in detalle.operaciones"
                                            :key="opIndex"
                                          >
                                            <div
                                              v-if="
                                                operacion.bloque_id ===
                                                  bloque.id
                                              "
                                            >
                                              {{ operacion.Noperacion }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="card-body table-responsive">
                          <table
                            class="table table-bordered table-striped table-hover table-sm p-2 shadow"
                          >
                            <thead class="bg-secondary">
                              <tr>
                                <th class="text-center">Tipo Vehículo</th>
                                <th class="text-center">Cantidad Vehículos</th>
                                <th class="text-center">Empresa</th>
                                <th class="text-center">Ruta</th>
                                <th class="text-center">Turno</th>
                                <th class="text-center">Producto</th>
                                <th class="text-center">Accesorios</th>
                                <th class="text-center">Bloque</th>
                                <th class="text-center">Operación</th>
                              </tr>
                            </thead>
                            <tbody id="tbody">
                              <tr>
                                <td class="text-center">
                                  {{ detalle.tipo_vh.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.cantidad_vehiculos }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.empresa.razon_social }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.ruta.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.turno.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.producto.nombre }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.accesorios }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.bloque ? detalle.bloque.nombre : "" }}
                                </td>
                                <td class="text-center">
                                  {{ detalle.operacion ? detalle.operacion.Noperacion : "" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="form-group">
              <button class="btn btn-default ml-1" @click="validacionVolver()">
                <i class="fas fa-reply"></i>
                <div>Volver</div>
              </button>
              <!-- <button
                class="btn bg-frontera text-white"
                v-if="form.id"
                @click="saveTotal()"
                :disabled="form.estado == 2 || btnDisabled"             
              >
                <div>Enviar</div>
              </button> -->
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import axios from "axios";
/* import vSelect from "vue-select"; */
import DetSolicitudForm from "./DetSolicitudForm";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "SolicitudForm",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    DetSolicitudForm,
    /* vSelect, */
  },

  data() {
    return {
      cargando: false,
      bloquear: false,
      user: {},
      file: null,
      accion: "",
      act: "",
      metodo: "",
      userRol: false,
      hoy: moment().format("YYYY-MM-DD"),
      btnDisabled: false,
      form: {
        id: null,
        user_id: null,
        user: {},
        fecha_solicitud: null,
        observaciones: null,
        justificacion: null,
        estado: null,
      },
      listasForms: {
        tipos_solicitudes: [],
        clases_solicitudes: [],
        tipos_emergencias: [],
        vicepreseidentes: [],
        sitios: [],
        bloques: [],
        empresas: [],
        tipos_tarifas: [],
        tipos_vehiculos: [],
        rutas: [],
        productosLiquidos: [],
        turnos: [],
        areas: [],
        tipos_presupuesto: [],
        responsables: [],
        gerentes: [],
        activosProduccion: [],
        estados: [],
      },
      guardar: 0,
      message: "",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    if (this.form.id) {
      return {
        form: {
          user_id: {
            required,
          },
          user: {
            required,
          },
          fecha_solicitud: {
            required,
          },
          observaciones: {
            required,
          },
          estado: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          user_id: {
            required,
          },
          user: {
            required,
          },
          fecha_solicitud: {
            required,
          },
          observaciones: {
            required,
          },
        },
      };
    }
  },
  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        await axios
          .get(
            "/api/funcionariosFrontera/tifSolicitudes/" + this.$route.params.id
          )
          .then(async (response) => {
            this.form = await response.data;
          });
        this.bloquear = this.form.estado == 2 ? true : false;
      } else {
        this.metodo = "POST";
        this.form.user = await this.$store.state.user;
        this.form.user_id = await this.$store.state.user.id;
      }
      await this.getUser();
      await this.userRoles();
      this.cargando = false;
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    validaFecha() {
      if (this.form.tipo_solicitud == 1 && this.form.fecha_solicitud) {
        let fecha_hoy = moment(this.hoy);
        let fecha_solicitud = moment(this.form.fecha_solicitud);
        if (
          fecha_solicitud.diff(fecha_hoy, "days") < 2 &&
          this.form.clase_solicitud == 2
        ) {
          this.form.fecha_solicitud = null;
          this.$swal({
            icon: "error",
            title:
              "Las Solicitudes Normales deben hacerce con más de un 48 horas de antelación.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    async getTipoClase() {
      await axios.get("/api/lista/183").then((response) => {
        this.listasForms.clases_solicitudes = response.data;
      });
    },

    async getTipoEmergencia() {
      await axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipos_emergencias = response.data;
      });
    },

    getVicepresidente() {
      const in_id = [163, 245];
      const params = {
        in_id,
      };
      axios
        .get("/api/admin/funcionarios/lista", {
          params,
        })
        .then((response) => {
          this.listasForms.vicepreseidentes = response.data;
        });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/funcionariosFrontera/tifSolicitudes",
          data: this.form,
        })
          .then((response) => {
            this.form.id = response.data.id;
            this.bloquear = response.data.estado == 2 ? true : false;
            this.$swal({
              text: "Se guardó la solicitud...",
              icon: "success",
              confirmButtonText: "Ok!",
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async getSitios() {
      await axios
        .get("/api/admin/sitios/lista", {
          params: { bloque_id: this.form.bloque_id },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        });
    },

    seleccionarSitio() {
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      } else {
        this.form.sitio_id = null;
      }
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    seleccionarBloque() {
      if (this.form.bloque) {
        this.form.bloque_id = this.form.bloque.id;
        this.getSitios();
      } else {
        this.form.bloque_id = null;
      }
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = await this.user.id;
      this.form.nombre = await this.user.name;
      this.form.identificacion = await this.user.n_document;
      this.form.celular = await this.user.cel;
      this.form.area = (await this.user.funcionario[0])
        ? this.user.funcionario[0].nArea
        : "";
    },

    validacionVolver() {
      /* this.$swal({
        title: "¿Está seguro de realizar esta acción?",
        text:
          "Por favor validar nuevamente si desea salir de esta pantalla, ya que en caso de aceptar perdera todo lo anteriormente diligenciado. Le recomendamos verificar si desea salir realmente de esta pantalla! En caso de que si oprima aceptar, en caso de que no oprima cancelar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar!",
      }).then((result) => {
        if (result.value) {
          return this.$router.replace("/FuncionariosFrontera/Tif/Solicitudes");
        }
      }); */
      return this.$router.replace("/FuncionariosFrontera/Tif/Solicitudes");
    },

    getArchivo(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    userRoles() {
      this.userRol = false;
      let usuario = this.$store.state.user;
      for (let i = 0; i < usuario.roles.length; i++) {
        let roles = usuario.roles[i];
        switch (roles.id) {
          case 44:
            this.userRol = true;
            break;
          default:
            break;
        }
      }
    },

    async getEmpresa() {
      await axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    async getTipoVh() {
      await axios
        .get("api/admin/tiposVehiculos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.tipos_vehiculos = response.data;
        });
    },

    async getRutas() {
      await axios.get("/api/tif/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
      });
    },

    /* async getProductosLiquidos() {
      await axios.get("/api/hidrocarburos/Productos/lista").then((response) => {
        this.listasForms.productosLiquidos = response.data;
      });
    }, */

    async getProductosLiquidos() {
      await axios
        .get("/api/admin/productos/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.productosLiquidos = response.data;
        });
    },

    async getTipoTarifa() {
      await axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipos_tarifas = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getTurnos() {
      await axios
        .get("/api/admin/turnos/lista", {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    async getAreas() {
      await axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    async getTipoPresupuesto() {
      await axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipos_presupuesto = response.data;
      });
    },

    async getResponsables() {
      await axios.get("/api/admin/funcionarios/list").then((response) => {
        this.listasForms.responsables = response.data;
        this.listasForms.gerentes = this.listasForms.responsables.filter(
          (funcionario) => funcionario.gerente_area === 1
        );
      });
    },

    getActivosProduccion() {
      axios.get("/api/lista/137").then((response) => {
        this.listasForms.activosProduccion = response.data;
      });
    },

    llenarModal(accion, detalle) {
      let data = {
        accion: accion,
        detalle: detalle,
      };
      this.$refs.DetSolicitudForm.getIndex(data);
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar este detalle?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/funcionariosFrontera/tifSolicitudes/detalle/" + id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getTipoSolicitud();
    await this.getEstados();
    await this.getTipoClase();
    await this.getTipoEmergencia();
    await this.getVicepresidente();
    await this.getBloques();
    await this.getTipoVh();
    await this.getEmpresa();
    await this.getRutas();
    await this.getProductosLiquidos();
    await this.getTipoTarifa();
    await this.getTurnos();
    await this.getAreas();
    await this.getTipoPresupuesto();
    await this.getResponsables();
    await this.getActivosProduccion();
    await this.getIndex();
    this.cargando = false;
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
